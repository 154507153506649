.rowContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 86px;
  background: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.25);
  position: fixed;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12), 0px 4px 8px rgba(0, 0, 0, 0.25);
  z-index: 99;
  width: 100%;
}
.container {
  height: 86px;
  display: flex;
  align-items: center;
  justify-content: end;
  // background: #ffffff;
  // border: 1px solid rgba(255, 255, 255, 0.25);
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12), 0px 4px 8px rgba(0, 0, 0, 0.25);
  max-width: 1920px;
  width: 100%;
  z-index: 99;
}
.blockLogo {
  position: absolute;
  float: left;
  left: 0px;
  a {
    font-style: normal;
    font-weight: 900;
    font-size: 35px;
    line-height: 140%;
    /* or 42px */
    text-transform: uppercase;
    color: #000000;
  }
  @media (max-width: 500px) {
    // left: 15px;
    a {
      font-size: 24px;
    }
    img {
      width: 140px;
    }
  }
}
.blockItemHeader {
  padding: auto;
  width: 120px;
  height: 86px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  :hover {
    cursor: pointer;
  }
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 38px;
  /* identical to box height, or 211% */
  letter-spacing: -0.500211px;
  text-transform: uppercase;
  color: #000000;
  @media (max-width: 800px) {
    display: none;
  }
}
.blockItemHeaderRegister {
  width: 180px !important;
}
.dropdown {
  position: relative;
  display: inline-block;
}
.dropdownContent {
  display: block;
  opacity: 0;
  position: absolute;
  background-color: #ffffff;
  min-width: 160px;
  z-index: 1;
  // border-bottom-left-radius: 10px;
  // border-bottom-right-radius: 10px;
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12), 0px 4px 8px rgba(0, 0, 0, 0.25);
  a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: none;
    text-align: left;
  }
}
.dropdown:hover .dropdownContent {
  display: block;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  a {
    display: block;
    background-color: transparent;
  }
}
.dropdown a:hover {
  color: #39adaa;
}
.blockHeader {
  color: #000000;
  height: 86px;
  align-items: center;
  display: flex;
}
// .blockHeader:hover .arrow180 {
//   transition: transform 0.2s ease-in-out;
//   transform: rotate(180deg);
// }

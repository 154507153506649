.line-betwen {
  width: 15%;
  height: 3px;
  background-color: #39adaa;
  position: relative;
  left: 42.5%;
  top: 180px;
}

.nav {
  position: relative;
  width: auto;
  display: inline-block;
  border: none;
}

.btn-nav {
  position: fixed;
  top: 42px;
  right: 40px;
  background: transparent;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 99999;
  display: none;
}

@media screen and (max-width: 800px) {
  .btn-nav {
    top: 16px;
    /* right: 17px; */
    display: block;
  }
}

.btn-nav:focus {
  outline: 0;
}

.icon-bar {
  display: block;
  margin: 6px 0;
  width: 35px;
  height: 5px;
  background-color: #39adaa;
}

.btn-nav:hover .icon-bar {
  background-color: #39adaa;
}

.nav-content {
  position: fixed;
  top: -100%;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: block;
  height: 100%;
  z-index: 9;
}

.nav-list {
  list-style: none;
  padding: 0;
  position: relative;
  top: 10%;
}

.item-anchor:after {
  content: '';
  position: absolute;
  width: 3px;
  height: 3px;
  left: 0;
  bottom: 0;
  z-index: 9;
  background: transparent;
}

.item-anchor {
  color: #fff;
  position: relative;
  text-decoration: none;
  padding: 10px;
  font-weight: 800;
  font-size: 31px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.07em;
  text-transform: uppercase;
}

.item-anchor:hover,
.item-anchor:focus {
  color: #39adaa;
}

.item-anchor:hover:after,
.item-anchor:focus:after {
  width: 100%;
  background: #39adaa;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.nav-item {
  margin: 20px auto;
  text-align: center;
}

.animated {
  display: block;
  margin: 0 auto;
}

.animated:hover .icon-bar,
.animated:focus .icon-bar {
  background-color: #39adaa;
}

.animated:focus {
  cursor: pointer;
  z-index: 9999;
}

.middle {
  margin: 0 auto;
}

.icon-bar {
  -webkit-transition: all 0.7s ease;
  -moz-transition: all 0.7s ease;
  -ms-transition: all 0.7s ease;
  -o-transition: all 0.7s ease;
  transition: all 0.7s ease;
  z-index: 999999;
}

.animated .icon-bar {
  z-index: 999999;
  background-color: #39adaa;
}

.animated .top {
  -webkit-transform: translateY(10px) rotateZ(45deg);
  -moz-transform: translateY(10px) rotateZ(45deg);
  -ms-transform: translateY(10px) rotateZ(45deg);
  -o-transform: translateY(10px) rotateZ(45deg);
  transform: translateY(10px) rotateZ(45deg);
}

.animated .bottom {
  -webkit-transform: translateY(-11px) rotateZ(-45deg);
  -moz-transform: translateY(-11px) rotateZ(-45deg);
  -ms-transform: translateY(-11px) rotateZ(-45deg);
  -o-transform: translateY(-11px) rotateZ(-45deg);
  transform: translateY(-11px) rotateZ(-45deg);
}

.animated .middle {
  width: 0;
}

@keyframes showNav {
  from {
    top: -100%;
  }
  to {
    top: 0;
  }
}

@-webkit-keyframes showNav {
  from {
    top: -100%;
  }
  to {
    top: 0;
  }
}

@-moz-keyframes showNav {
  from {
    top: -100%;
  }
  to {
    top: 0;
  }
}

@-o-keyframes showNav {
  from {
    top: -100%;
  }
  to {
    top: 0;
  }
}

.showNav {
  -webkit-animation: showNav 1s ease forwards;
  -moz-animation: showNav 1s ease forwards;
  -o-animation: showNav 1s ease forwards;
  animation: showNav 1s ease forwards;
}

@keyframes hideNav {
  from {
    top: 0;
  }
  to {
    top: -100%;
  }
}

@-webkit-keyframes hideNav {
  from {
    top: 0;
  }
  to {
    top: -100%;
  }
}

@-moz-keyframes hideNav {
  from {
    top: 0;
  }
  to {
    top: -100%;
  }
}

@-o-keyframes hideNav {
  from {
    top: 0;
  }
  to {
    top: -100%;
  }
}

.hideNav {
  -webkit-animation: hideNav 1s ease forwards;
  -moz-animation: hideNav 1s ease forwards;
  -o-animation: hideNav 1s ease forwards;
  animation: hideNav 1s ease forwards;
}

.hidden {
  display: none;
}

.market-btn {
  display: inline-block;
  padding: 0.3125rem 0.875rem;
  padding-left: 2.8125rem;
  -webkit-transition: border-color 0.25s ease-in-out,
    background-color 0.25s ease-in-out;
  transition: border-color 0.25s ease-in-out, background-color 0.25s ease-in-out;
  border: 1px solid #e7e7e7;
  background-position: center left 0.75rem;
  background-color: #fff;
  background-size: 1.5rem 1.5rem;
  background-repeat: no-repeat;
  text-decoration: none;
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4Ij4KPHBvbHlnb24gc3R5bGU9ImZpbGw6IzVDREFERDsiIHBvaW50cz0iMjkuNTMsMCAyOS41MywyNTEuNTA5IDI5LjUzLDUxMiAyOTkuMDA0LDI1MS41MDkgIi8+Cjxwb2x5Z29uIHN0eWxlPSJmaWxsOiNCREVDQzQ7IiBwb2ludHM9IjM2OS4wNjcsMTgwLjU0NyAyNjIuMTc1LDExOS40NjcgMjkuNTMsMCAyOTkuMDA0LDI1MS41MDkgIi8+Cjxwb2x5Z29uIHN0eWxlPSJmaWxsOiNEQzY4QTE7IiBwb2ludHM9IjI5LjUzLDUxMiAyOS41Myw1MTIgMjYyLjE3NSwzODMuNTUxIDM2OS4wNjcsMzIyLjQ3IDI5OS4wMDQsMjUxLjUwOSAiLz4KPHBhdGggc3R5bGU9ImZpbGw6I0ZGQ0E5NjsiIGQ9Ik0zNjkuMDY3LDE4MC41NDdsLTcwLjA2Myw3MC45NjFsNzAuMDYzLDcwLjk2MWwxMDguNjg4LTYyLjg3N2M2LjI4OC0zLjU5Myw2LjI4OC0xMS42NzcsMC0xNS4yNyAgTDM2OS4wNjcsMTgwLjU0N3oiLz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==);
}

.market-btn .market-button-subtitle {
  display: block;
  margin-bottom: -0.25rem;
  color: #888;
  font-size: 0.75rem;
}
/* .google-btn {
} */
